import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import commaNumber from 'comma-number';

const ReportStatus = ({ xls, translate, lang, role }) => {
    const [datas, setData] = useState([]);
    const getData = async () => {
        const  datas  = await axios.get("/customer/getMainSafeIQD");
        $('#safeIQD').html(commaNumber(datas.data[0].balance));

        const  datas1  = await axios.get("/customer/getMainSafeUSD");
        $('#safeUSD').html(commaNumber(datas1.data[0].balance)); 

        const  datas2  = await axios.get("/customer/getContractUSD");
        if(datas2.status){
            $('#contractUSD').html(commaNumber(datas2.data[0].amount_due)); 
        }
        

        const  datas3  = await axios.get("/customer/getContractIQD");
        if(datas3.data.length>0){
            $('#contractIQD').html(commaNumber(datas3.data[0].amount_due)); 
        }
        else{
            $('#contractIQD').html(commaNumber('0'));
        }
        

        const dataGroupBy = await axios.get("/customer/customerpaymentDetailCompaniesAPIGroupBy");
        $('#balanceUSD').html(commaNumber(dataGroupBy.data[0].balance));
        $('#balanceIQD').html(commaNumber(dataGroupBy.data[1].balance));

    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    });
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">تقرير عام</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تقرير عام</h6>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`safe_name_${lang}`)} $</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="safeUSD"> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`contract_${lang}`)} $</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="contractUSD">   </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_due_${lang}`)} $</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="balanceUSD">  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br />
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`safe_name_${lang}`)}  د.ع </h5>
                                                    <span className="h4 font-weight-bold mb-0" id="safeIQD"> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`contract_${lang}`)}  د.ع </h5>
                                                    <span className="h4 font-weight-bold mb-0" id="contractIQD"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_due_${lang}`)}  د.ع </h5>
                                                    <span className="h4 font-weight-bold mb-0" id="balanceIQD"> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportStatus;