import Sidebar from "./Components/Layout/Sidebar";
import Navbar from "./Components/Layout/Navbar";
import Footer from "./Components/Layout/Footer";
import translate from './translation';
import * as icon from 'react-feather';
import { useEffect } from "react";
import $ from "jquery";

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';
const getWords = (keyword) => {
  const item = translate.find((item) => item.keyword === keyword);
  if (item) {
    return item.words;
  }
};

const APP = ({ children }) => {
  useEffect(() => {
    if (lang !== "en") {
      $('#ltr-style').remove();
    } else {
      $('body').removeAttr('dir')
      $('#rtl-style').remove();
    }

  }, [])
  return (
    <div className="main-wrapper">
      <Sidebar icon={icon} translate={getWords} lang={lang} />
      <div className="page-wrapper">
        <Navbar icon={icon} translate={getWords} lang={lang} />
        {children}
        <Footer translate={getWords} lang={lang} />
      </div>
    </div>
  );
};

export default APP;