import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import commaNumber from 'comma-number';

const ItemBalance = ({ xls, translate, lang, role }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/orderItem/item-summary");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`available_items_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`available_items_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`code_${lang}`)}</th>
                                        <th>{translate(`item_${lang}`)}</th>
                                        <th>{translate(`unit_${lang}`)}</th>
                                        <th>{translate(`order_${lang}`)}</th>
                                        <th>{translate(`producted_${lang}`)}</th>
                                        <th>{translate(`damages_${lang}`)}</th>
                                        <th>{translate(`transfer_${lang}`)}</th>
                                        <th>{translate(`available_items_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item[`store_name_${lang}`]}</td>
                                            <td>{item.item_code}</td>
                                            <td>{item[`item_name_${lang}`]}</td>
                                            <td>{item.item_unit === "m" ? translate(`sqm_${lang}`) : item.item_unit === "kg" ? translate(`kg_${lang}`) : item.item_unit === "qty" ? translate(`qty_${lang}`) : item.item_unit === "shada" ? translate(`shada_${lang}`) : item.item_unit === "top" ? translate(`top_${lang}`) : item.item_unit === "parcha" ? translate(`parcha_${lang}`) : item.item_unit === "ltr" ? translate(`ltr_${lang}`) : item.item_unit === "bundle" ? translate(`bundle_${lang}`) : translate(`cartoon_${lang}`)}</td>
                                            <td>{commaNumber(item.qty_order)}</td>
                                            <td>{commaNumber(item.qty_production)}</td>
                                            <td>{commaNumber(item.qty_damages)}</td>
                                            <td>{commaNumber(item.qty_transfer)}</td>
                                            <td>{commaNumber(item.item_balance)} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemBalance;