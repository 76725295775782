import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Dashboard from './Dashboard';
import Customers from './Accounts/Customers';
import Stores from './General/Stores';
import Items from './General/Items';
import Groups from './General/Groups';
import OrderList from './Orders/List';
import OrderNew from './Orders/New';
import OrderUpdate from './Orders/Edit';
import OrderInvoice from './Orders/Invoice';
import ReturnList from "./Returns/List";
import ReturnNew from "./Returns/New";
import ReturnEdit from "./Returns/Edit";
import ReturnInvoice from "./Returns/Invoice";
import ProductionList from './Productions/List';
import ProductionNew from './Productions/New';
import ProductionUpdate from './Productions/Edit';
import ServiceList from './Productions/Services/List';
import ServiceListById from './Productions/Services/ListById';
import DamageList from './Damages/List';
import DamageListBy from './Damages/ListBy';
import SaleList from './Sales/List';
import SaleNew from './Sales/New';
import SaleEdit from './Sales/Edit';
import SaleInvoice from './Sales/Invoice';
import ItemBalance from './Reports/ItemBalance';
import SaleItems from './Reports/SaleItems';
import Productions from './Reports/Productions';
import OrderItems from './Reports/OrderItems';
import AvailableProducts from './Reports/AvailableProducts';
import OrderPayments from './Payments/OrderPayments';
import SalePayments from './Payments/SalePayments';
import Safe from './Finance/Safe';
import Voucher from './Finance/Voucher';
import ReportVoucher from './Reports/ReportVouchers';
import Expenses from './Finance/Expenses';
import Employee from './Finance/Employees';
import Accounts from './Accounts/Accounts';
import Currencies from './General/Currencies';
import Sinvoice from './Payments/Sinvoice';
import Oinvoice from './Payments/Oinvoice';
import Login from './Login';
import CompanyInvoice from './Accounts/OrderInovice';
import SellerInvoice from './Accounts/SaleInvoice';
import ReportExpenses from './Reports/ReportExpenses';
import Translations from './General/Translations';
import translate from '../translation.json';
import NewCollections from './Productions/Collection/New';
import EditCollections from './Productions/Collection/Edit';
import CollectionList from './Productions/Collection/List';
import ReturnsItemList from './Reports/ReturnsItemList';
import ContractList from './Contracts/List';
import Partners from './Contracts/Partners';
import Partner from './Contracts/Partner';
import Services from './Contracts/Services';
import TransferList from './Transfers/List';
import TransferNew from './Transfers/New';
import Cinvoice from './Payments/Cinvoice';
import ContractPayments from './Payments/ContractPayments';
import CustomInvoice from './Accounts/CustomInvoice';
import CustomInvoices from './Accounts/Invoices';
import TransferEdit from './Transfers/Edit';
import Service from './Contracts/Service';
import ServiceInv from './Contracts/ServInv';
import ReportBalance from './Reports/ReportBalance';
import PrintContract from './Contracts/PrintContract';
import ReportBalanceHeader from './Reports/ReportBalanceHeader';
import PaymentDetailsCompanies from './Accounts/PaymentDetailCompanies';
import CustomerPaymentListCompanies from './Payments/CustomerPaymentCompanies';
import ReportStatus from './Reports/ReportStatus';
import ContractPartnerPayment from './Payments/PartnerContractPayment';

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';
const getWords = (keyword) => {
    const item = translate.find((item) => item.keyword === keyword);
    if (item) {
        return item.words;
    }
};

const xls = () => {
    const elt = document.querySelector('.dt-tbl');
    const wb = window.XLSX.utils.table_to_book(elt, { sheet: "Salar Prefab" });
    window.XLSX.writeFile(wb, 'salar-prefab.xlsx');
}


const role = localStorage.getItem('role') ? localStorage.getItem('role') : false;
const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <video autoPlay muted loop id="myVideo">
                        <source src="../../assets/intro.mp4" type="video/mp4" />
                    </video>
                    <Login icon={icon} translate={getWords} lang={lang} />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Dashboard role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/accounts",
        element: <APP><Accounts role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/customers",
        element: <APP><Customers role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/paymentdetailcompanies",
        element: <APP><PaymentDetailsCompanies role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/seller/invoice/:id",
        element: <APP><SellerInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/company/invoice/:id/:currency_id",
        element: <APP><CompanyInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/stores",
        element: <APP><Stores role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/groups",
        element: <APP><Groups role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/items",
        element: <APP><Items role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/orders/list",
        element: <APP><OrderList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/order/new",
        element: <APP><OrderNew role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/order/edit/:id",
        element: <APP><OrderUpdate role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/order/invoice/:id",
        element: <APP><OrderInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/orders/payment",
        element: <APP><OrderPayments role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/orders/payment/invoice/:id",
        element: <APP><Oinvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/returns/list",
        element: <APP><ReturnList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/return/new",
        element: <APP><ReturnNew role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/return/edit/:id",
        element: <APP><ReturnEdit role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/return/invoice/:id",
        element: <APP><ReturnInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collection/new",
        element: <APP><NewCollections role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collection/edit/:id",
        element: <APP><EditCollections role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collection/list",
        element: <APP><CollectionList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/productions/list",
        element: <APP><ProductionList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/production/new",
        element: <APP><ProductionNew role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/production/edit/:id",
        element: <APP><ProductionUpdate role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services/list",
        element: <APP><ServiceList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services/list/:id",
        element: <APP><ServiceListById role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/damages",
        element: <APP><DamageList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/damages/:id",
        element: <APP><DamageListBy role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sales/list",
        element: <APP><SaleList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sale/new",
        element: <APP><SaleNew role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sale/edit/:id",
        element: <APP><SaleEdit role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sale/invoice/:id",
        element: <APP><SaleInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contracts/printcontract/:id",
        element: <APP><PrintContract role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sales/payment",
        element: <APP><SalePayments role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sales/payment/invoice/:id",
        element: <APP><Sinvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/item-balance",
        element: <APP><ItemBalance role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/available-products",
        element: <APP><AvailableProducts role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/order-items",
        element: <APP><OrderItems role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/productions",
        element: <APP><Productions role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/sale-items",
        element: <APP><SaleItems role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/returns",
        element: <APP><ReturnsItemList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/finances/safe",
        element: <APP><Safe role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/finance/voucher",
        element: <APP><Voucher role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/vouchers",
        element: <APP><ReportVoucher role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/finance/expenses",
        element: <APP><Expenses role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/finance/expenses/report",
        element: <APP><ReportExpenses role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/finance/employees",
        element: <APP><Employee role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/currencies",
        element: <APP><Currencies role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/translations",
        element: <APP><Translations role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contracts/list",
        element: <APP><ContractList role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/partners",
        element: <APP><Partners role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/partner/:id",
        element: <APP><Partner role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/services",
        element: <APP><Services role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/service-item/:id",
        element: <APP><Service role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/service/invoice/:id",
        element: <APP><ServiceInv role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/payment/invoice/:id",
        element: <APP><Cinvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contract/payment",
        element: <APP><ContractPayments role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/transfers/list",
        element: <APP><TransferList role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/transfer/new",
        element: <APP><TransferNew role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/transfer/edit/:id",
        element: <APP><TransferEdit role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/custom/invoice/:id",
        element: <APP><CustomInvoice role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/payments/customerpaymentlistcompanies",
        element: <APP><CustomerPaymentListCompanies role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/custom/invoice",
        element: <APP><CustomInvoices role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/balances",
        element: <APP><ReportBalance role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/reportstatus",
        element: <APP><ReportStatus role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report/balanceheader",
        element: <APP><ReportBalanceHeader role={role} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/partner/payment",
        element: <APP><ContractPartnerPayment role={role} xls={xls} icon={icon} translate={getWords} lang={lang} /></APP>,
        errorElement: <ErrorPage />,
    },
]
);

export default Routes;