/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import Select from 'react-select'

const List = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/service/list?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get('/service/list');
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 5]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [group, setGroup] = useState([]);
    const getGroup = async () => {
        const { data } = await axios.get("/group/list");
        setGroup(data.map((item) => {
            return {
                value: item.id,
                label: item[`group_name_${lang}`],
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate
            };
        }));
    }


    useEffect(() => {
        getData();
        getGroup();
        getCurrency();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`receipt_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`receipt_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`type_${lang}`)}</th>
                                        <th>{translate(`group_${lang}`)}</th>
                                        <th>{translate(`cost_${lang}`)}</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <th>{index + 1}</th>
                                            <td>{item[`service_name_${lang}`]}</td>
                                            <td>{item[`group_name_${lang}`]}</td>
                                            <td>{commaNumber(item.cost)} {item.symbol}</td>
                                            <td>
                                                <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={item.note ? item.note : "-"}>
                                                    {item.note ? item.note.slice(0, 20) + '...' : "-"}
                                                </span>
                                            </td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                                    <>
                                                        <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit-${item.id}`}>
                                                            <icon.Edit size={16} />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                            Swal.fire({
                                                                title: translate(`warning_${lang}`),
                                                                text: translate(`warning_text_${lang}`),
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: translate(`yes_${lang}`),
                                                                cancelButtonText: translate(`no_${lang}`)
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/service/delete/${item.id}`).then((res) => {
                                                                        if (res.data !== "done") {
                                                                            toast.error(res.data);
                                                                        } else {
                                                                            getData();
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size={16} />
                                                        </span>
                                                    </>
                                                )}
                                                <div className="modal fade" id={`edit-${item.id}`} tabIndex={-1} aria-labelledby={`edit-${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-xl">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`edit_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/service/update/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        e.target.reset();
                                                                        window.$(`#edit-${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_krd" className="form-control" defaultValue={item.service_name_krd} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)}  ({translate("arabic")}) <span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_ar" className="form-control" defaultValue={item.service_name_ar} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)}  ({translate("english")}) <span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_en" className="form-control" defaultValue={item.service_name_en} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`price_${lang}`)} <span className="text-danger">*</span></label>
                                                                                <input type="number" name="cost" step="any" className="form-control" placeholder="0.00" defaultValue={item.cost} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)} </label>
                                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} defaultValue={{ value: item.currency_id, label: item.symbol }} required onChange={(e) => { $('input[name="rate"]').val(e.rate); }} />
                                                                            <input type="hidden" name="rate" defaultValue={item.rate} required />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`group_${lang}`)} <span className="text-danger">*</span></label>
                                                                                <Select options={group} name="group_id" defaultValue={{ value: item.group_id, label: lang === "krd" ? item.group_name_krd : lang === "ar" ? item.group_name_ar : item.group_name_en }} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`note_${lang}`)} </label>
                                                                                <textarea className="form-control" name="note" rows={3} defaultValue={item.note} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)} </button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`update_${lang}`)} </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default List;