/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import commaNumber from "comma-number";
import Select from 'react-select'

const Employee = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [employee, setEmployee] = useState([]);
    const getEmployee = async () => {
        const { data } = await axios.get("/employee/list");
        setEmployee(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 7]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`store_name_${lang}`],
            };
        }));
    }

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: item.symbol
            };
        }));
    }

    useEffect(() => {
        getEmployee();
        getStore();
        getCurrency();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`employee_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`item_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#newEmployee">
                                            <icon.UserPlus size="20" />
                                            <span className="ms-2">{translate(`new_employee_${lang}`)}</span>
                                        </button>

                                    </div>
                                )}
                                <div className="modal fade" id="newEmployee" tabIndex={-1} aria-labelledby="newEmployee" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{translate(`new_employee_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/employee/add`, data).then(res => {
                                                    if (res.data !== 'done') {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getEmployee();
                                                        e.target.reset();
                                                        window.$('#newEmployee').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="full_name" placeholder={translate(`name_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="phone" placeholder={translate(`phone_no_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="address" className="form-label">{translate(`address_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="address" placeholder={translate(`address_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="nationality" className="form-label">{translate(`nationality_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="nationality" placeholder={translate(`nationality_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="job" className="form-label">{translate(`job_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="job" placeholder={translate(`job_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="started" className="form-label">{translate(`started_${lang}`)}</label>
                                                        <input type="date" className="form-control" name="started" defaultValue={new Date().toISOString().slice(0, 10)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="salary" className="form-label">{translate(`salary_${lang}`)}</label>
                                                        <input type="number" className="form-control" name="salary" defaultValue="0" required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="currency_id" className="form-label">{translate(`currency_${lang}`)}</label>
                                                        <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="store" className="form-label">{translate(`store_${lang}`)}</label>
                                                        <Select options={store} name="store_id" placeholder={translate(`store_${lang}`)} required />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`name_${lang}`)}</th>
                                        <th>{translate(`phone_no_${lang}`)}</th>
                                        <th>{translate(`address_${lang}`)}</th>
                                        <th>{translate(`nationality_${lang}`)}</th>
                                        <th>{translate(`job_${lang}`)}</th>
                                        <th>{translate(`salary_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`started_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employee && employee.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.full_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.address}</td>
                                            <td>{item.nationality}</td>
                                            <td>{item.job}</td>
                                            <td>{commaNumber(item.salary)} {item.symbol}</td>
                                            <td>{item.store_name_krd}</td>
                                            <td>{new Date(item.started).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser") && (
                                                    <>
                                                        <span type="button" className="badge bg-warning mx-2" data-bs-toggle="modal" data-bs-target={`#updateEmployee${item.id}`}>
                                                            <icon.Edit size="16" />
                                                        </span>

                                                        <span type="button" className="badge bg-danger"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: translate(`warning_${lang}`),
                                                                    text: translate(`warning_text_${lang}`),
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: translate(`yes_${lang}`),
                                                                    cancelButtonText: translate(`no_${lang}`)
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/employee/delete/${item.id}`).then(res => {
                                                                            if (res.data !== 'err') {
                                                                                getEmployee();
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }} >
                                                            <icon.Trash size="16" />
                                                        </span>
                                                    </>
                                                )}
                                                <div className="modal fade" id={`updateEmployee${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/employee/update/${item.id}`, data).then(res => {
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getEmployee();
                                                                        window.$(`#updateEmployee${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)}</label>
                                                                        <input type="text" className="form-control" id="full_name" name="full_name" defaultValue={item.full_name} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)}</label>
                                                                        <input type="text" className="form-control" id="phone" name="phone" defaultValue={item.phone} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="address" className="form-label">{translate(`address_${lang}`)}</label>
                                                                        <input type="text" className="form-control" id="address" name="address" defaultValue={item.address} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="nationality" className="form-label">{translate(`nationality_${lang}`)}</label>
                                                                        <input type="text" className="form-control" name="nationality" defaultValue={item.nationality} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="job" className="form-label">{translate(`job_${lang}`)}</label>
                                                                        <input type="text" className="form-control" name="job" defaultValue={item.job} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="started" className="form-label">{translate(`started_${lang}`)}</label>
                                                                        <input type="date" className="form-control" name="started" defaultValue={new Date(item.started).toISOString().slice(0, 10)} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="salary" className="form-label">{translate(`salary_${lang}`)}</label>
                                                                        <input type="number" className="form-control" name="salary" defaultValue={item.salary} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="currency_id" className="form-label">{translate(`currency_${lang}`)}</label>
                                                                        <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} defaultValue={{ value: item.currency_id, label: item.symbol }} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="store" className="form-label">{translate(`store_${lang}`)}</label>
                                                                        <Select options={store} name="store_id" placeholder={translate(`currency_${lang}`)} defaultValue={{ value: item.store_id, label: item.store_name_krd }} required />
                                                                    </div>

                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employee;