/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from 'comma-number';

const New = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "orders") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const [customerId, setcustomerId] = useState('')
    const [returnsId, setreturnsId] = useState(JSON.parse(localStorage.getItem('orderId')))

    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, [customerId]);

    const [seller, setSeller] = useState([]);
    const getSeller = async () => {
        const { data } = await axios.get('/customer/seller');
        setSeller(data.map((item) => {
            return {
                value: item.id,
                label: item[`full_name_${lang}`],
            };
        }));
    }

    const [orderItems, setorderItems] = useState([])
    const getOrderItems = async (cid) => {
        if (customerId && cid) {
            if (customerId) {
                const { data } = await axios.get(`/orderItem/return-list/${customerId}`);
                setorderItems(data.map((item) => {
                    return {
                        value: item.id,
                        order_id: item.order_id,
                        customer_id: item.customer_id,
                        item_id: item.item_id,
                        symbol: item.symbol,
                        item_unit: item.item_unit,
                        price: item.price,
                        label: `${item[`item_name_${lang}`]} - ${item.item_code}`,
                    };
                }));
            }
            if (cid) {
                const { data } = await axios.get(`/orderItem/return-list/${cid}`);
                setorderItems(data.map((item) => {
                    return {
                        value: item.id,
                        order_id: item.order_id,
                        customer_id: item.customer_id,
                        item_id: item.item_id,
                        symbol: item.symbol,
                        item_unit: item.item_unit,
                        price: item.price,
                        label: `${item[`item_name_${lang}`]} - ${item.item_code}`,
                    };
                }));
            }
        }

    }

    const [itemSelected, setitemSelected] = useState(false)
    const submit = () => {
        if (!itemSelected) {
            toast.error("Plase Select an Item")
            return
        }
        axios.post(`/return/add?returnId=${returnsId ? returnsId : null}`, {
            order_item_id: itemSelected.value,
            order_id: itemSelected.order_id,
            customer_id: itemSelected.customer_id,
            item_id: itemSelected.item_id,
            price: itemSelected.price,
            symbol: itemSelected.symbol,
            item_unit: itemSelected.item_unit,
            created: $('#created').val()
        }).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                getReturnItem(itemSelected.customer_id);
                setreturnsId(res.data)
                getReturnItem(res.data)
                localStorage.setItem('returnId', res.data)
            }
        })
    }

    const [returnItem, setReturnItem] = useState([]);
    const getReturnItem = async (reid) => {
        if (returnsId && reid) {
            if (reid) {
                const { data } = await axios.get(`/return/list/${reid}`);
                setReturnItem(data);
            } else {
                const { data } = await axios.get(`/return/list/${returnsId}`);
                setReturnItem(data);
            }

        }
    }

    useEffect(() => {
        getSeller();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`return_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"> {translate(`return_list_${lang}`)}</h5>
                            <div className="row">
                                <div className="col-md-3 mb-3 mt-2">
                                    <label className="form-label"> {translate(`company_${lang}`)}</label>
                                    <Select options={seller} id="customer_id" placeholder={translate(`company_${lang}`)} required onChange={(e) => {
                                        setcustomerId(e.value);
                                        getOrderItems(e.value);
                                        getReturnItem()
                                    }} />
                                </div>
                                <div className="col-md-3 mb-3 mt-2">
                                    <label className="form-label">{translate(`select_item_${lang}`)}</label>
                                    <Select options={orderItems && orderItems} placeholder={translate(`select_item_${lang}`)} onChange={(e) => { setitemSelected(e) }} />
                                </div>
                                <div className="col-md-3 mb-3 mt-2">
                                    <label className="form-label"> {translate(`date_${lang}`)}</label>
                                    <input type="date" className="form-control" id="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-3 mb-3 mt-2">
                                    <button type="button" className="btn btn-sm btn-inverse-dark w-100 mt-4" onClick={submit} >
                                        {translate(`add_${lang}`)}
                                        <icon.Download className="mx-2 mt-1" />
                                    </button>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`group_${lang}`)}</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`height_${lang}`)}</th>
                                                    <th>{translate(`width_${lang}`)}</th>
                                                    <th>{translate(`weight_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {returnItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item[`group_name_${lang}`]}</td>
                                                        <td>{item[`item_name_${lang}`]}</td>
                                                        <td> {item.quantity ?
                                                            <input type="number" className="form-control" name="quantity" step="any" defaultValue={item.quantity} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/return/update/${item.id}`, { quantity: e.target.value, item_id: item.item_id }).then((res) => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getReturnItem();
                                                                    }
                                                                });
                                                            }} /> : '-'}
                                                        </td>
                                                        <td> {item.height ?
                                                            <input type="number" className="form-control" name="height" step="any" defaultValue={item.height} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/return/update/${item.id}`, { height: e.target.value, width: item.width, item_id: item.item_id }).then((res) => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getReturnItem();
                                                                    }
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.width ?
                                                            <input type="number" className="form-control" name="width" step="any" defaultValue={item.width} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/return/update/${item.id}`, { width: e.target.value, height: item.height, item_id: item.item_id }).then((res) => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getReturnItem();
                                                                    }
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.weight ?
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.weight} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/return/update/${item.id}`, { weight: e.target.value, item_id: item.item_id }).then((res) => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getReturnItem();
                                                                    }
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.price} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/return/update/${item.id}`, { price: e.target.value, item_id: item.item_id }).then((res) => {
                                                                    getReturnItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>{commaNumber(item.total_price)} {item.symbol}</td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/return/list/${item.id}`).then(() => {
                                                                    getReturnItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mt-3">
                                <div className="col-md-5 mb-3">
                                    <h5 className="card-title">{translate(`return_summery_${lang}`)}</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <tbody>
                                                <tr >
                                                    <td className="text-bold-800">{translate(`area${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(returnItem.reduce((total, item) => total + (item.height * item.width), 0).toFixed(2))} {translate(`sqm_${lang}`)}</td>
                                                </tr>
                                                <tr className="bg-light">
                                                    <td className="text-bold-800">{translate(`qty_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(returnItem.reduce((total, item) => total + item.quantity, 0).toFixed(2))} {translate(`qty_text_${lang}`)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-800"> {translate(`weight_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(returnItem.reduce((total, item) => total + (item.weight), 0).toFixed(2))} {translate(`kg_${lang}`)}</td>
                                                </tr>
                                                <tr className="bg-dark text-white">
                                                    <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end"> {commaNumber(returnItem.reduce((total, item) => total + item.total_price, 0).toFixed(2),)} {returnItem[0] && returnItem[0].symbol}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => {
                                            navigate('/returns/list');
                                            localStorage.removeItem('returnId');
                                        }} >
                                            {translate(`save_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default New;