import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ContractPayments = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/contract/payments?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get(`/contract/payments`);
            Data = data
        }
        setData(Data)
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            order: [[0, 'desc']],
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 6, 7]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [2, 3, 4, 5]
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`sale_payment_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`sale_payment_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`customers_${lang}`)}</th>
                                        <th>{translate(`invoice_${lang}`)}</th>
                                        <th>{translate(`paid_${lang}`)}</th>
                                        <th>{translate(`due_${lang}`)}</th>
                                        <th>{translate(`result_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.contract_payments_id}</td>
                                            <td>{item[`full_name_${lang}`]}</td>
                                            <td>
                                                <a href={`/contracts/list`} target={'_blank'} rel="noreferrer">
                                                    {item.id}
                                                </a>
                                            </td>
                                            <td>{commaNumber(item.amount_paid)} {item.symbol}</td>
                                            <td>{commaNumber(item.amount_due.toFixed(2))} {item.symbol}</td>
                                            <td>{item.amount_due < 0 ? translate(`paid_${lang}`) : translate(`due_${lang}`)}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                                    <>
                                                        <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#payment${item.id}`} >
                                                            <icon.Edit size={16} />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                            Swal.fire({
                                                                title: translate(`warning_${lang}`),
                                                                text: translate(`warning_text_${lang}`),
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: translate(`yes_${lang}`),
                                                                cancelButtonText: translate(`no_${lang}`)
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/contract/payment/${item.contract_payments_id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="16" />
                                                        </span>
                                                    </>
                                                )}
                                                <a href={`/contract/payment/invoice/${item.contract_payments_id}`} className="badge bg-primary mx-1" target={'_blank'} rel="noreferrer">
                                                    <icon.Printer size="16" />
                                                </a>
                                            </td>
                                            <div className="modal fade" id={`payment${item.id}`} tabIndex={-1} aria-labelledby="payment" aria-hidden="true">
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> {translate(`sale_payment_${lang}`)} </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/contract/payment/${item.contract_payments_id}`, data).then(res => {
                                                                if (isNaN(res.data)) {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    window.$(`#payment${item.id}`).modal('hide')
                                                                    e.target.reset();
                                                                    getData();
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="amount_paid" className="col-form-label">{translate(`amount_paid_${lang}`)}:</label>
                                                                    <input type="number" className="form-control" name="amount_paid" step={"any"} defaultValue={item.amount_paid} required />
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                        <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                        <input type="date" className="form-control" name="created" defaultValue={new Date(new Date(item.created).getTime() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]}  />
                                                                    </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContractPayments;