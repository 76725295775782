/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from "comma-number";

const List = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/damage/list");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 5]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/orderItem/item-summary');
        setItems(data.map((item) => {
            return {
                value: item.item_id,
                label: `${item.item_code} - ${item[`item_name_${lang}`]} - ${commaNumber(item.max_price.toFixed(2))} $`,
                item_unit: item.item_unit,
                item_price: item.max_price,
                item_balance: item.item_balance,
                store_id: item.store_id

            };
        }));
    }
    const [itemUnits, setItemUnits] = useState([]);
    const itemSelected = (e) => {
        setItemUnits(
            <>
                {e.item_unit === 'm' ? (
                    <>
                        <div className="mb-3">
                            <label className="form-label">{translate(`height_${lang}`)} <span className="text-danger">*</span></label>
                            <input type="number" name="height" step="any" className="form-control" required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{translate(`width_${lang}`)} <span className="text-danger">*</span></label>
                            <input type="number" name="width" step="any" className="form-control" required />
                        </div>
                    </>)
                    : e.item_unit === 'qty' ? (
                        <div className="mb-3">
                            <label className="form-label">{translate(`qty_${lang}`)} <span className="text-danger">*</span></label>
                            <input type="number" name="quantity" step="any" className="form-control" required />
                        </div>) : (
                        <div className="mb-3">
                            <label className="form-label">{translate(`weight_${lang}`)} <span className="text-danger">*</span></label>
                            <input type="number" name="weight" step="any" className="form-control" required />
                        </div>
                    )}
                <input type="hidden" name="store_id" value={e.store_id} />
            </>
        );
    }

    useEffect(() => {
        getData();
        getItems();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`damage_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`damage_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`add_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                const x = items.find(x => x.value === parseInt(data.item_id));
                                                let item_balance = 0;
                                                if (x.item_unit === 'm') {
                                                    item_balance = x.item_balance - data.height * data.width;
                                                }
                                                if (x.item_unit === 'qty') {
                                                    item_balance = x.item_balance - data.quantity;
                                                }
                                                if (x.item_unit === 'kg') {
                                                    item_balance = x.item_balance - data.weight;
                                                }
                                                if (item_balance < 0) {
                                                    toast.error(translate(`item_not_available_${lang}`));
                                                    return;
                                                }
                                                axios.post(`/damage/add`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getData();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <label className="form-label">{translate(`item_${lang}`)} <span className="text-danger">*</span></label>
                                                        <Select options={items} name="item_id" placeholder={translate(`select_item_${lang}`)} onChange={(e) => { itemSelected(e); }} />
                                                    </div>
                                                    {itemUnits}
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`code_${lang}`)}</th>
                                        <th>{translate(`item_${lang}`)}</th>
                                        <th>{translate(`size_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.item_code}</td>
                                            <td><Link to={`/damages/${item.item_id}`}>{item[`item_name_${lang}`]}</Link></td>
                                            <td>{item.qty_damages} {item.item_unit === "m" ? translate(`sqm_${lang}`) : item.item_unit === "kg" ? translate(`kg_${lang}`) : item.item_unit === "qty" ? translate(`qty_${lang}`) : item.item_unit === "shada" ? translate(`shada_${lang}`) : item.item_unit === "top" ? translate(`top_${lang}`) : item.item_unit === "parcha" ? translate(`parcha_${lang}`) : item.item_unit === "ltr" ? translate(`ltr_${lang}`) : item.item_unit === "bundle" ? translate(`bundle_${lang}`) : translate(`cartoon_${lang}`)}</td>
                                            <td>{item[`store_name_${lang}`]}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default List;