/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'

const Safe = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [safe, setSafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setSafe(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 4, 5]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`store_name_${lang}`],
            };
        }));
    }

    useEffect(() => {
        getSafe();
        getStore();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`account_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> {translate(`account_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>

                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`new_account_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/finance/safe`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getSafe();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_krd" className="form-label"> {translate(`safe_name_${lang}`)} ({translate("kurdi")})</label>
                                                        <input type="text" className="form-control" name="safe_name_krd" placeholder={translate(`safe_name_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_ar" className="form-label">{translate(`safe_name_${lang}`)} ({translate("arabic")})</label>
                                                        <input type="text" className="form-control" name="safe_name_ar" placeholder={translate(`safe_name_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_en" className="form-label">{translate(`safe_name_${lang}`)} ({translate("english")})</label>
                                                        <input type="text" className="form-control" name="safe_name_en" placeholder={translate(`safe_name_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_code" className="form-label">{translate(`safe_code_${lang}`)}</label>
                                                        <input type="text" className="form-control" name="safe_code" placeholder={translate(`safe_code_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="store_id" className="form-label">{translate(`store_${lang}`)}</label>
                                                        <Select options={store} name="store_id" placeholder={translate(`store_${lang}`)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="store" className="form-label">{translate(`safe_primary_${lang}`)}</label>
                                                        <select className="form-select" name="isPrimary" required>
                                                            <option value="0">{translate(`yes_${lang}`)}</option>
                                                            <option value="1">{translate(`no_${lang}`)}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>{translate(`safe_code_${lang}`)}</th>
                                        <th>{translate(`username_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`safe_primary_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {safe && safe.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.safe_name_krd}</td>
                                            <td>{item.safe_code}</td>
                                            <td>{item.username}</td>
                                            <td>{item.store_name_krd}</td>
                                            <td>{item.isPrimary === 0 ? translate(`sub_${lang}`) : translate(`primary_${lang}`)}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser") && (
                                                    <>
                                                        <span type="button" className="badge bg-warning mx-2" data-bs-toggle="modal" data-bs-target={`#update${item.id}`}>
                                                            <icon.Edit size="16" />
                                                        </span>

                                                    </>
                                                )}
                                                <div className="modal fade" id={`update${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/finance/safe/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getSafe();
                                                                        window.$(`#update${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="safe_name_krd" className="form-label"> {translate(`safe_name_${lang}`)} ({translate("kurdi")})</label>
                                                                        <input type="text" className="form-control" name="safe_name_krd" placeholder={translate(`safe_name_${lang}`)} defaultValue={item.safe_name_krd} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="safe_name_ar" className="form-label">{translate(`safe_name_${lang}`)} ({translate("arabic")})</label>
                                                                        <input type="text" className="form-control" name="safe_name_ar" placeholder={translate(`safe_name_${lang}`)} defaultValue={item.safe_name_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="safe_name_en" className="form-label">{translate(`safe_name_${lang}`)} ({translate("english")})</label>
                                                                        <input type="text" className="form-control" name="safe_name_en" placeholder={translate(`safe_name_${lang}`)} defaultValue={item.safe_name_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="safe_code" className="form-label">{translate(`safe_code_${lang}`)}</label>
                                                                        <input type="text" className="form-control" name="safe_code" placeholder={translate(`safe_code_${lang}`)} defaultValue={item.safe_code} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="store_id" className="form-label">{translate(`store_${lang}`)}</label>
                                                                        <Select options={store} name="store_id" defaultValue={{ value: item.store_id, label: item.safe_name_krd }} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="store" className="form-label">{translate(`safe_primary_${lang}`)}</label>
                                                                        <Select options={[
                                                                            { value: '1', label: translate(`yes_${lang}`) },
                                                                            { value: '0', label: translate(`no_${lang}`) },
                                                                        ]} name="isPrimary" placeholder={translate(`safe_primary_${lang}`)} defaultValue={{ value: item.isPrimary, label: item.isPrimary === 0 ? translate(`no_${lang}`) : translate(`yes_${lang}`) }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Safe;