/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'

const New = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const [pnId, setPnId] = useState(localStorage.getItem('production_name_id'))


    const [selectProduct, setSelectProduct] = useState([]);
    const getProductGroup = async () => {
        const { data } = await axios.get("/production/product");
        setSelectProduct(data.map((item) => {
            return {
                value: item.id,
                label: item[`product_name_${lang}`]
            };
        }));
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/item/list');
        setItems(data.map((item) => {
            return {
                value: item.id,
                label: `${item[`item_name_${lang}`]} - ${item.item_code}`,
                item_unit: item.item_unit,
            };
        }));
    }

    const [collectionItem, setCollectionItem] = useState([]);
    const getCollectionItem = async () => {
        const { data } = await axios.get(`/collection/items/${pnId}`)
        setCollectionItem(data)
    }

    useEffect(() => {
        getItems();
        getProductGroup();
        getCollectionItem();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });

        if (pnId) {
            $('.pnd').remove();
            $('.pim').show();
        }
    }, [pnId]);



    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`collection_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{translate(`select_item_${lang}`)}</h5>
                            <div className="row pnd">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label"> {translate(`product_${lang}`)}</label>
                                    <Select options={selectProduct} name="product_name_id" placeholder={translate(`product_${lang}`)} required onChange={(e) => {
                                        localStorage.setItem('production_name_id', e.value)
                                        setPnId(e.value)
                                    }} />
                                </div>
                            </div>
                            <div className="row pim" style={{ display: "none" }}>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">{translate(`select_item_${lang}`)}</label>
                                    <Select options={items} placeholder={translate(`select_item_${lang}`)} onChange={(e) => {
                                        axios.post(`/collection/add`, { production_name_id: pnId, item_id: e.value, item_unit: e.item_unit }).then((res) => {
                                            !isNaN(res.data) ? getCollectionItem() : toast.error(res.data)
                                        })
                                    }} />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`product_name_${lang}`)}</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`height_${lang}`)}</th>
                                                    <th>{translate(`width_${lang}`)}</th>
                                                    <th>{translate(`weight_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {collectionItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item[`product_name_${lang}`]}</td>
                                                        <td>{item[`item_name_${lang}`]}</td>
                                                        <td> {item.item_unit === "qty" || item.item_unit === "shada" || item.item_unit === "top" || item.item_unit === "parcha" || item.item_unit === "ltr" || item.item_unit === "cartoon" || item.item_unit === "bundle" ?
                                                            <input type="number" className="form-control" name="quantity" step="any" defaultValue={item.quantity} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/collection/update/${item.id}`, { quantity: e.target.value }).then(() => {
                                                                    getCollectionItem();
                                                                });
                                                            }} /> : '-'}
                                                        </td>
                                                        <td> {item.item_unit === "m" ?
                                                            <input type="number" className="form-control" name="height" step="any" defaultValue={item.height} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/collection/update/${item.id}`, { height: e.target.value }).then(() => {
                                                                    getCollectionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.item_unit === "m" ?
                                                            <input type="number" className="form-control" name="width" step="any" defaultValue={item.width} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/collection/update/${item.id}`, { width: e.target.value }).then(() => {
                                                                    getCollectionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.item_unit === "kg" ?
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.weight} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/collection/update/${item.id}`, { weight: e.target.value }).then(() => {
                                                                    getCollectionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/collection/delete/${item.id}`).then(() => {
                                                                    getCollectionItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => {
                                            localStorage.removeItem('production_name_id');
                                            navigate('/collection/list');
                                        }} >
                                            {translate(`save_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default New;