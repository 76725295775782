/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from 'comma-number';

const New = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "transfer") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const transferId = JSON.parse(localStorage.getItem('transferId'));

    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        if (transferId) {
            $('#transfers').hide();
            $('#transfer_item').show();
        } else {
            $('#transfers').show();
            $('#transfer_item').hide();
        }
    }, [transferId]);

    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`store_name_${lang}`],
            };
        }));
    }

    const [contracts, setContracts] = useState([]);
    const getContract = async () => {
        const { data } = await axios.get('/contract/list');
        setContracts(data.map((item) => {
            return {
                value: item.id,
                label: item[`title_${lang}`],
            };
        }));
    }

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/orderItem/item-summary');
        setItems(data.map((item) => {
            return {
                value: item.item_id,
                label: `${item.item_code} - ${item[`item_name_${lang}`]} - ${commaNumber(item.max_price.toFixed(2))} $`,
                item_unit: item.item_unit,
                item_price: item.max_price,
                item_balance: item.item_balance,
            };
        }));
    }

    const itemSelected = (e) => {
        let x = JSON.parse(localStorage.getItem('transferId'));
        axios.post(`/transferItem/add`, {
            transfer_id: x,
            item_id: e.value,
            item_unit: e.item_unit,
            price: e.item_price,
            currency_id: 1
        }).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                geTransferItem();
            }
        })
    }

    const [transferItem, setTransferItem] = useState([]);
    const geTransferItem = async () => {
        const { data } = await axios.get(`/transferItem/list/${JSON.parse(localStorage.getItem('transferId'))}`);
        setTransferItem(data);
    }

    useEffect(() => {
        getStore();
        getItems();
        getContract();
        geTransferItem();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`transfer_${lang}`)} </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card" id="transfers" style={{ display: "none" }}>
                    <div className="card">
                        <form className="form-sample" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData);
                            axios.post(`/transfer/add`, data).then(res => {
                                if (isNaN(res.data)) {
                                    toast.error(res.data, {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                } else {
                                    localStorage.setItem('transferId', res.data)
                                    $('#transfers').hide();
                                    $('#transfer_item').show();
                                }
                            });
                        }}>
                            <div className="card-body">
                                <h5 className="card-title">{translate(`transfer_${lang}`)} </h5>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`store_${lang}`)}</label>
                                        <Select options={store} name="to_store" placeholder={translate(`store_${lang}`)} required />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`contract_${lang}`)}</label>
                                        <Select options={contracts} name="contract_id" placeholder={translate(`contract_${lang}`)} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">{translate(`next_${lang}`)}
                                    <icon.ArrowLeft className="mx-2 mt-1" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card" id="transfer_item" style={{ display: "none" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{translate(`select_item_${lang}`)} </h5>
                            <div className="row">
                                <div className="col-md-4 mb-3 mt-2">
                                    <div className="input-group mb-3">
                                        <div className="col-12">
                                            <Select options={items} placeholder={translate(`select_item_${lang}`)} onChange={(e) => { itemSelected(e); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`single_contract_${lang}`)}</th>
                                                    <th>{translate(`store_${lang}`)}</th>
                                                    <th>{translate(`group_${lang}`)}</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`height_${lang}`)}</th>
                                                    <th>{translate(`width_${lang}`)}</th>
                                                    <th>{translate(`weight_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transferItem.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item[`title_${lang}`]}</td>
                                                        <td>{item[`store_name_${lang}`]}</td>
                                                        <td>{item[`group_name_${lang}`]}</td>
                                                        <td>{item[`item_name_${lang}`]}</td>
                                                        <td>{item.quantity ?
                                                            <input type="number" className="form-control" name="quantity" step="any" defaultValue={item.quantity} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - e.target.value;
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/transferItem/update/${item.id}`, { quantity: e.target.value }).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }} /> : '-'}
                                                        </td>
                                                        <td>{item.height ?
                                                            <input type="number" className="form-control" name="height" step="any" defaultValue={item.height} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - (e.target.value * item.width);
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/transferItem/update/${item.id}`, { height: e.target.value }).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.width ?
                                                            <input type="number" className="form-control" name="width" step="any" defaultValue={item.width} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - (e.target.value * item.height);
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/transferItem/update/${item.id}`, { width: e.target.value }).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.weight ?
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.weight} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - e.target.value;
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/transferItem/update/${item.id}`, { weight: e.target.value }).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.price} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/transferItem/update/${item.id}`, { price: e.target.value }).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>{commaNumber(item.total_price)} {item.symbol}</td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/transferItem/list/${item.id}`).then(() => {
                                                                    geTransferItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => {
                                            localStorage.removeItem('transferId');
                                            navigate('/transfers/list');
                                        }} >
                                            {translate(`save_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default New;