import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import commaNumber from 'comma-number';
import Swal from "sweetalert2";
import Select from 'react-select'


const CustomInvoices = ({ icon, xls, translate, lang, role }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/customer/custom-inv");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 5]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [customers, setCustomers] = useState([]);
    const getCustomers = async () => {
        const { data } = await axios.get('/customer/list');
        setCustomers(data.map((item) => {
            return {
                value: item.id,
                label: item[`full_name_${lang}`],
            };
        }));
    }

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }


    useEffect(() => {
        getData();
        getCustomers();
        getCurrency();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        // eslint-disable-next-line
    }, []);
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`custom_inv_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`custom_inv_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`customers_${lang}`)}</th>
                                        <th>{translate(`invoice_type_${lang}`)}</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`total_price_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item[`full_name_${lang}`]}</td>
                                            <td>{item.inv_type === "income" ? translate(`income_${lang}`) : translate(`outcome_${lang}`)}</td>
                                            <td>{commaNumber(item.note)}</td>
                                            <td>{commaNumber(item.amount)}{item.symbol}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <icon.Edit size={16} />
                                                </span>
                                                <span type="button" className="badge bg-danger" onClick={() => {
                                                    Swal.fire({
                                                        title: translate(`warning_${lang}`),
                                                        text: translate(`warning_text_${lang}`),
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: translate(`yes_${lang}`),
                                                        cancelButtonText: translate(`no_${lang}`)
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            axios.delete(`/customer/custom-inv/${item.id}`).then(res => {
                                                                !isNaN(res.data) ? getData() : toast.error(res.data);
                                                            })
                                                        }
                                                    })
                                                }} >
                                                    <icon.Trash size={16} />
                                                </span>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/customer/custom-inv/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getData();
                                                                        toast.success(translate(`success_${lang}`))
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`customers_${lang}`)}<span className="text-danger">*</span></label>
                                                                                <Select options={customers} name="customer_id" defaultValue={{ value: item.customer_id, label: item[`full_name_${lang}`] }} placeholder={translate(`customers_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString(item.created).split('T')[0]} />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                            <Select options={currency} name="currency_id" defaultValue={{ value: item.currency_id, label: item.symbol }} placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                                $('input[name="rate"]').val(e.rate);
                                                                                $('#crc').text(e.label)
                                                                            }} />
                                                                            <input type="hidden" name="rate" defaultValue={item.rate} required />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`amount_${lang}`)}</label>
                                                                            <input type="number" className="form-control" id="amount" name="amount" defaultValue={item.amount} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="type" className="form-label">{translate(`invoice_type_${lang}`)}</label>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type1" defaultValue={'income'} defaultChecked={item.inv_type === 'income'} />
                                                                                    <label className="form-check-label" htmlFor="type1">{translate(`income_${lang}`)}</label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type2" defaultValue={'outcome'} defaultChecked={item.inv_type === 'outcome'} />
                                                                                    <label className="form-check-label" htmlFor="type2">{translate(`outcome_${lang}`)}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="form-label"> {translate(`note_${lang}`)}</label>
                                                                            <textarea className="form-control" name="note" defaultValue={item.note} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomInvoices;