/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Select from 'react-select'
import Swal from "sweetalert2";

const List = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/production/list?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get('/production/list');
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0, 1, 2, 8]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [group, setGroup] = useState([]);
    const getGroup = async () => {
        const { data } = await axios.get("/group/list");
        setGroup(data.map((item) => {
            return {
                value: item.id,
                label: item[`group_name_${lang}`],
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate
            };
        }));
    }

    const [productName, setProductName] = useState([]);
    const getProductGroup = async () => {
        const { data } = await axios.get("/production/product");
        setProductName(data)
    };

    useEffect(() => {
        getData();
        getGroup();
        getCurrency();
        getProductGroup();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);

    const [service, setService] = useState([]);

    const [summery, setSummery] = useState([]);
    const getSummery = (id) => {
        axios.get(`/production/summery/${id}`).then(res => {
            setSummery(res.data)
        })
    }


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`product_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`product_list_${lang}`)}</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <Link to="/production/new" className="btn btn-sm btn-primary mx-2">
                                        <icon.Plus size="20" />
                                        <span className="ms-2">{translate(`add_${lang}`)}</span>
                                    </Link>
                                    <button className="btn btn-sm btn-light mx-2" data-bs-toggle="modal" data-bs-target="#productName">
                                        <icon.Layers size="20" />
                                        <span className="ms-2">{translate(`product_${lang}`)}</span>
                                    </button>
                                    <div className="modal fade" id="productName" tabIndex={-1} aria-labelledby="productName" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">{translate(`product_${lang}`)}</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <div className="modal-body">
                                                    <form className="form-sample" onSubmit={(e) => {
                                                        e.preventDefault();
                                                        const formData = new FormData(e.target);
                                                        const data = Object.fromEntries(formData);
                                                        axios.post("/production/product", data).then(res => {
                                                            if (!isNaN(res.data)) {
                                                                getProductGroup();
                                                            } else {
                                                                toast.error(res.data)
                                                            }
                                                        })
                                                    }}>
                                                        <div className="row">
                                                            <div className="col-md-4 mb-3">
                                                                <div className="form-group row">
                                                                    <label htmlFor="product_name_krd" className="col-12 form-label">{translate(`product_name_krd`)}</label>
                                                                    <div className="col-12">
                                                                        <input type="text" className="form-control" name="product_name_krd" placeholder={translate(`product_name_krd`)} required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <div className="form-group row">
                                                                    <label htmlFor="product_name_ar" className="col-12 form-label">{translate(`product_name_ar`)}</label>
                                                                    <div className="col-12">
                                                                        <input type="text" className="form-control" name="product_name_ar" placeholder={translate(`product_name_ar`)} required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <div className="form-group row">
                                                                    <label htmlFor="group_namproduct_name_ene_en" className="col-12 form-label">{translate(`product_name_en`)}</label>
                                                                    <div className="col-12">
                                                                        <input type="text" className="form-control" name="product_name_en" placeholder={translate(`product_name_en`)} required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <button type="submit" className="btn btn-sm btn-dark">  {translate(`add_${lang}`)} </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="tabl-resposive">
                                                        <table className="table table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{translate(`product_name_krd`)}</th>
                                                                    <th>{translate(`product_name_ar`)}</th>
                                                                    <th>{translate(`product_name_en`)}</th>
                                                                    <th>{translate(`options_${lang}`)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {productName.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td><input className="form-control" defaultValue={item.product_name_krd} onChange={(e) => { axios.put(`/production/product/${item.id}`, { product_name_krd: e.target.value }).then(() => { getProductGroup() }) }} /></td>
                                                                            <td><input className="form-control" defaultValue={item.product_name_ar} onChange={(e) => { axios.put(`/production/product/${item.id}`, { product_name_ar: e.target.value }).then(() => { getProductGroup() }) }} /></td>
                                                                            <td><input className="form-control" defaultValue={item.product_name_en} onChange={(e) => { axios.put(`/production/product/${item.id}`, { product_name_en: e.target.value }).then(() => { getProductGroup() }) }} /></td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: translate(`warning_${lang}`),
                                                                                        text: translate(`warning_text_${lang}`),
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: translate(`yes_${lang}`),
                                                                                        cancelButtonText: translate(`no_${lang}`)
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            axios.delete(`/production/product/${item.id}`).then(res => {
                                                                                                !isNaN(res.data) ? getProductGroup() : toast.error(res.data);
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }} >
                                                                                    <icon.Trash size={16} />
                                                                                </button>
                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Link to="/collection/list" className="btn btn-sm btn-dark mx-2">
                                        <icon.Settings size="20" />
                                        <span className="ms-2">{translate(`collection_${lang}`)}</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`product_${lang}`)}</th>
                                        <th>{translate(`single_contract_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`sqm_${lang}`)}</th>
                                        <th>{translate(`productions_${lang}`)}</th>
                                        <th>{translate(`receipt_${lang}`)}</th>
                                        <th>{translate(`total_cost_${lang}`)}</th>
                                        <th>{translate(`attachment_${lang}`)}</th>
                                        <th>{translate(`receipt_${lang}`)}</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item[`product_name_${lang}`]}</td>
                                            <td>{item[`title_${lang}`] ? item[`title_${lang}`] : '-'}</td>
                                            <td>{item[`store_name_${lang}`]}</td>
                                            <td>{commaNumber(item.square_meter)}</td>
                                            <td>{commaNumber(item.product_cost.toFixed(2))} $</td>
                                            <td>{commaNumber(item.service_cost.toFixed(2))} $</td>
                                            <td>{commaNumber(item.total_cost.toFixed(2))} $</td>
                                            <td>{item.attachment ? <a href={`/${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                            <td>
                                                <span type="button" className="badge bg-light text-dark mx-1" data-bs-toggle="modal" data-bs-target={`#service-list-${item.id}`} onClick={() =>
                                                    axios.get(`/service/list/${item.id}`).then(res => {
                                                        setService(res.data);
                                                    })}>
                                                    <icon.Search size={16} />
                                                </span>
                                            </td>
                                            <td>
                                                <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={item.note ? item.note : "-"}>
                                                    {item.note ? item.note.slice(0, 20) + '...' : "-"}
                                                </span>
                                            </td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <icon.MoreVertical size={16} />
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                                            <>
                                                                <span type="button" data-bs-toggle="modal" data-bs-target={`#service-add-${item.id}`} className="dropdown-item">
                                                                    <icon.Plus size={16} />
                                                                    <span className="mx-2">{translate(`receipt_${lang}`)}</span>
                                                                </span>
                                                                <Link to={`/production/edit/${item.id}`} className="dropdown-item">
                                                                    <icon.Edit size={16} />
                                                                    <span className="mx-2">{translate(`edit_${lang}`)}</span>
                                                                </Link>

                                                                <span type="button" className="dropdown-item" onClick={() => {
                                                                    Swal.fire({
                                                                        title: translate(`warning_${lang}`),
                                                                        text: translate(`warning_text_${lang}`),
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: translate(`yes_${lang}`),
                                                                        cancelButtonText: translate(`no_${lang}`)
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/production/all/${item.id}`).then(res => {
                                                                                res.data !== "done" ? toast.error(res.data) : getData();
                                                                            })
                                                                        }
                                                                    })
                                                                }} >
                                                                    <icon.Trash size={16} />
                                                                    <span className="mx-2">{translate(`delete_${lang}`)}</span>
                                                                </span>
                                                            </>
                                                        )}
                                                        <span type="button" data-bs-toggle="modal" data-bs-target={`#summery-${item.id}`} className="dropdown-item" onClick={() => getSummery(item.id)} >
                                                            <icon.TrendingUp size={16} />
                                                            <span className="mx-2">{translate(`analyze_${lang}`)}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`service-add-${item.id}`} tabIndex={-1} aria-labelledby={`service-add-${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`add_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.production_id = item.id;
                                                                axios.post(`/service/add`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        e.target.reset();
                                                                        window.$(`#service-add-${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_krd" className="form-control" placeholder={translate(`title_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)} ({translate("arabic")}) <span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_ar" className="form-control" placeholder={translate(`title_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`title_${lang}`)} ({translate("english")}) <span className="text-danger">*</span></label>
                                                                                <input type="text" name="service_name_en" className="form-control" placeholder={translate(`title_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => { $('input[name="rate"]').val(e.rate); }} />
                                                                            <input type="hidden" name="rate" required />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`price_${lang}`)}<span className="text-danger">*</span></label>
                                                                                <input type="number" name="cost" step="any" className="form-control" placeholder="0.00 " required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`group_${lang}`)}<span className="text-danger">*</span></label>
                                                                                <Select options={group} name="group_id" placeholder={translate(`group_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`note_${lang}`)}</label>
                                                                                <textarea className="form-control" name="note" rows={3} placeholder={translate(`note_${lang}`)} defaultValue={""} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`summery-${item.id}`} tabIndex={-1} aria-labelledby={`summery-${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">   {translate(`analyze_${lang}`)} {lang === "krd" ? item.product_name_krd : lang === "ar" ? item.product_name_ar : item.product_name_en} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>{translate(`group_${lang}`)}</th>
                                                                                <th>{translate(`type_${lang}`)}</th>
                                                                                <th>{translate(`cost_${lang}`)}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {summery.map((obj, x) => (
                                                                                <tr key={x}>
                                                                                    <td>{x + 1}</td>
                                                                                    <td>{lang === "krd" ? obj.group_name_krd : lang === "ar" ? obj.group_name_ar : obj.group_name_en}</td>
                                                                                    <td>{obj._type === "services" ? translate(`services_${lang}`) : translate(`productions_${lang}`)}  </td>
                                                                                    <td>{commaNumber(obj.total.toFixed(2))} $</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`service-list-${item.id}`} tabIndex={-1} aria-labelledby={`service-list-${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`receipt_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <table className="table table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>{translate(`type_${lang}`)}</th>
                                                                            <th>{translate(`group_${lang}`)}</th>
                                                                            <th>{translate(`cost_${lang}`)}</th>
                                                                            <th>{translate(`note_${lang}`)}</th>
                                                                            <th>{translate(`date_${lang}`)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {service.map((i, x) => (
                                                                            <tr key={i.id}>
                                                                                <th>{x + 1}</th>
                                                                                <td>{i[`service_name_${lang}`]}</td>
                                                                                <td>{i[`group_name_${lang}`]}</td>
                                                                                <td>{commaNumber(i.cost.toFixed(2))} {i.symbol}</td>
                                                                                <td>{i.note ? i.note : "-"}</td>
                                                                                <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <a href={`/services/list/${item.id}`} className="btn btn-primary" target="_blank" rel="noreferrer"> {translate(`edit_${lang}`)} <icon.ArrowUpLeft size={18} /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default List;