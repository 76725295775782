import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Swal from "sweetalert2";

const List = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "orders") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/return/list?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get(`/return/list`);
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 8]
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);



    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`return_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`return_list_${lang}`)}</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <Link to="/return/new" className="btn btn-sm btn-primary">
                                        <icon.Plus size="20" />
                                        <span className="ms-2">{translate(`add_${lang}`)}</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`company_${lang}`)}</th>
                                        <th>{translate(`qty_${lang}`)}</th>
                                        <th>{translate(`height_${lang}`)}</th>
                                        <th>{translate(`width_${lang}`)}</th>
                                        <th>{translate(`weight_${lang}`)}</th>
                                        <th>{translate(`amount_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item[`customer_name_${lang}`]}</td>
                                            <td>{item.quantity ? commaNumber(item.quantity) : '-'}</td>
                                            <td>{item.height ? commaNumber(item.height) : '-'}</td>
                                            <td>{item.width ? commaNumber(item.width) : '-'}</td>
                                            <td>{item.weight ? commaNumber(item.weight) : '-'}</td>
                                            <td>{commaNumber(item.total_price.toFixed(3))} {item.symbol}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <icon.MoreVertical size={16} />
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {(role === "admin" || role === "superuser" || role === "operations" || role === "orders") && (
                                                            <>
                                                                <Link to={`/return/edit/${item.id}?cid=${item.customer_id}`} className="dropdown-item">
                                                                    <icon.Edit size={16} />
                                                                    <span className="mx-2">{translate(`edit_${lang}`)}</span>
                                                                </Link>
                                                                <span type="button" className="dropdown-item" onClick={() => {
                                                                    Swal.fire({
                                                                        title: translate(`warning_${lang}`),
                                                                        text: translate(`warning_text_${lang}`),
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: translate(`yes_${lang}`),
                                                                        cancelButtonText: translate(`no_${lang}`)
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/return/all/${item.id}`).then(res => {
                                                                                res.data !== "done" ? toast.error(res.data) : getData();
                                                                            })
                                                                        }
                                                                    })
                                                                }} >
                                                                    <icon.Trash size={16} />
                                                                    <span className="mx-2">{translate(`delete_${lang}`)}</span>
                                                                </span>
                                                            </>
                                                        )}
                                                        <Link to={`/return/invoice/${item.id}`} target={'_blank'} className="dropdown-item">
                                                            <icon.Printer size={16} />
                                                            <span className="mx-2">{translate(`print_${lang}`)}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`payment${item.id}`} tabIndex={-1} aria-labelledby="payment" aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`order_payment_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.order_id = item.id;
                                                                axios.post(`/order/payment`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        window.$(`#payment${item.id}`).modal('hide')
                                                                        e.target.reset();
                                                                        getData();
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="due" className="col-form-label">{translate(`amount_due_${lang}`)}:</label>
                                                                        <input type="number" className="form-control" id={`due${item.id}`} defaultValue={item.amount_due} disabled />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="amount_paid" className="col-form-label">{translate(`amount_paid_${lang}`)}:</label>
                                                                        <input type="number" className="form-control" name="amount_paid" id={`paid${item.id}`} step="any" defaultValue={0} onChange={(e) => {
                                                                            if (e.target.value > item.amount_due) {
                                                                                $(`#paid${item.id}`).val(item.amount_due);
                                                                                $(`#due${item.id}`).val(0);
                                                                            } else {
                                                                                $(`#due${item.id}`).val(item.amount_due - e.target.value);
                                                                            }
                                                                        }} required />
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                        <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default List;