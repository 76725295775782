/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from 'react-select'
import commaNumber from "comma-number";

const Customers = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/customer/list");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 5]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [3, 4, 6, 7]
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };


    const [customerGroup, setCustomerGroup] = useState([]);
    const [selectGroup, setSelectGroup] = useState([]);
    const getCustomerGroup = async () => {
        const { data } = await axios.get("/customer/group");
        setCustomerGroup(data);
        setSelectGroup(data.map((item) => {
            return {
                value: item.id,
                label: item[`group_name_${lang}`]
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }

    useEffect(() => {
        getData();
        getCurrency();
        getCustomerGroup();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [orders, setorders] = useState([])
    const [companyPayment, setcompanyPayment] = useState([])
    const getCompany = async (id) => {
        const { data: orders } = await axios.get(`/order/company/${id}`);
        const { data: payment } = await axios.get(`/order/company/payment/${id}`);
        setorders(orders), setcompanyPayment(payment)
        if (orders.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-order')) {
                        window.$('.company-order').DataTable();
                    } else {
                        window.$('.company-order').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-payment')) {
                        window.$('.company-payment').DataTable();
                    } else {
                        window.$('.company-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    const [sales, setsales] = useState([])
    const [buyerPayment, setbuyerPayment] = useState([])
    const getBuyer = async (id) => {
        const { data: sales } = await axios.get(`/sale/buyer/${id}`);
        const { data: payment } = await axios.get(`/sale/buyer/payment/${id}`);
        setsales(sales), setbuyerPayment(payment)
        if (sales.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-sales')) {
                        window.$('.buyer-sales').DataTable();
                    } else {
                        window.$('.buyer-sales').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-payment')) {
                        window.$('.buyer-payment').DataTable();
                    } else {
                        window.$('.buyer-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`accounts_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`cusotmer_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-light mx-2" data-bs-toggle="modal" data-bs-target="#production-code">
                                            <icon.Users size="20" />
                                            <span className="ms-2">{translate(`group_${lang}`)}</span>
                                        </button>
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.UserPlus size="20" />
                                            <span className="ms-2">{translate(`new_account_${lang}`)}</span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="production-code" tabIndex={-1} aria-labelledby="production-code" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{translate(`group_${lang}`)} {translate(`customers_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <div className="modal-body">
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post("/customer/group", data).then(res => {
                                                        if (!isNaN(res.data)) {
                                                            getCustomerGroup();
                                                        } else {
                                                            toast.error(res.data)
                                                        }
                                                    })
                                                }}>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="group_name_krd" className="col-12 form-label">{translate(`group_krd`)}</label>
                                                                <div className="col-12">
                                                                    <input type="text" className="form-control" name="group_name_krd" placeholder={translate(`group_krd`)} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="group_name_ar" className="col-12 form-label">{translate(`group_ar`)}</label>
                                                                <div className="col-12">
                                                                    <input type="text" className="form-control" name="group_name_ar" placeholder={translate(`group_ar`)} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="group_name_en" className="col-12 form-label">{translate(`group_en`)}</label>
                                                                <div className="col-12">
                                                                    <input type="text" className="form-control" name="group_name_en" placeholder={translate(`group_en`)} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <button type="submit" className="btn btn-sm btn-dark">  {translate(`add_${lang}`)} </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="tabl-resposive">
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{translate(`group_krd`)}</th>
                                                                <th>{translate(`group_ar`)}</th>
                                                                <th>{translate(`group_en`)}</th>
                                                                <th>{translate(`options_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {customerGroup.map((item, index) => {
                                                                return (
                                                                    <tr key={item.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td><input className="form-control" defaultValue={item.group_name_krd} onChange={(e) => { axios.put(`/customer/group/${item.id}`, { group_name_krd: e.target.value }).then(() => { getCustomerGroup() }) }} /></td>
                                                                        <td><input className="form-control" defaultValue={item.group_name_ar} onChange={(e) => { axios.put(`/customer/group/${item.id}`, { group_name_ar: e.target.value }).then(() => { getCustomerGroup() }) }} /></td>
                                                                        <td><input className="form-control" defaultValue={item.group_name_en} onChange={(e) => { axios.put(`/customer/group/${item.id}`, { group_name_en: e.target.value }).then(() => { getCustomerGroup() }) }} /></td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                                                                Swal.fire({
                                                                                    title: translate(`warning_${lang}`),
                                                                                    text: translate(`warning_text_${lang}`),
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true,
                                                                                    confirmButtonColor: '#3085d6',
                                                                                    cancelButtonColor: '#d33',
                                                                                    confirmButtonText: translate(`yes_${lang}`),
                                                                                    cancelButtonText: translate(`no_${lang}`)
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        axios.delete(`/customer/group/${item.id}`).then(res => {
                                                                                            !isNaN(res.data) ? getCustomerGroup() : toast.error(res.data);
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }} >
                                                                                <icon.Trash size={16} />
                                                                            </button>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`new_account_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/customer/add`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getData();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label"> {translate(`group_${lang}`)}<span className="text-danger">*</span></label>
                                                                <Select options={selectGroup} name="customer_group_id" placeholder={translate(`group_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="type" className="form-label">{translate(`account_type_${lang}`)} <span className="text-danger">*</span></label>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="customer_type" id="inlineRadio1" defaultValue="buyer" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="inlineRadio1">{translate(`buyer_${lang}`)}</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="customer_type" id="inlineRadio2" defaultValue="seller" />
                                                                    <label className="form-check-label" htmlFor="inlineRadio2">{translate(`company_${lang}`)}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="full_name_krd" placeholder={translate(`name_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)}  ({translate("arabic")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="full_name_ar" placeholder={translate(`name_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)}  ({translate("english")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="full_name_en" placeholder={translate(`name_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)}  ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="address_krd" placeholder={translate(`address_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("arabic")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="address_ar" placeholder={translate(`address_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("english")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="address_en" placeholder={translate(`address_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)} <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" name="phone_number" placeholder={translate(`phone_no_${lang}`)} required />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`account_type_${lang}`)}</th>
                                        <th>{translate(`group_${lang}`)}</th>
                                        <th>{translate(`name_${lang}`)}</th>
                                        <th>{translate(`phone_no_${lang}`)}</th>
                                        <th>{translate(`address_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.customer_type === "buyer" ? (<span className="badge bg-light text-dark" type="button" data-bs-toggle="modal" data-bs-target="#buyer" onClick={() => getBuyer(item.id)}>&nbsp; {translate(`buyer_${lang}`)} &nbsp;</span>) : (<span className="badge bg-dark" type="button" data-bs-toggle="modal" data-bs-target="#company" onClick={() => getCompany(item.id)}>{translate(`company_${lang}`)}</span>)}</td>
                                            <td>{item[`group_name_${lang}`]}</td>
                                            <td>{lang === "krd" ? item.full_name_krd : lang === "ar" ? item.full_name_ar : item.full_name_en}</td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.address_krd}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {role === "admin" || role === "superuser" &&
                                                    <>

                                                        <span type="button" className="badge bg-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: translate(`warning_${lang}`),
                                                                text: translate(`warning_text_${lang}`),
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: translate(`yes_${lang}`),
                                                                cancelButtonText: translate(`no_${lang}`)
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/customer/delete/${item.id}`).then(res => {
                                                                        !isNaN(res.data) ? getData() : toast.error(res.data);
                                                                    })
                                                                }
                                                            })
                                                        }} >
                                                            <icon.Trash size={16} />
                                                        </span>
                                                    </>
                                                }
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <icon.Edit size={16} />
                                                </span>
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-dark mx-1" data-bs-toggle="modal" data-bs-target={`#inv${item.id}`}>
                                                        <icon.FileText size={16} />
                                                    </span>
                                                    : null
                                                }
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-primary mx-1" data-bs-toggle="modal" data-bs-target={`#printer${item.id}`}>
                                                        <icon.Printer size={16} />
                                                    </span> :
                                                    <a href={`/company/invoice/${item.id}`} className="badge bg-primary mx-1">
                                                        <icon.Printer size={16} />
                                                    </a>
                                                }
                                                <div className="modal fade" id={`printer${item.id}`} tabIndex={-1} aria-labelledby={`printer${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-md">
                                                        <div className="modal-content" style={{ background: 'transparent', border: 'none' }}>
                                                            <div className="modal-body text-center">
                                                                <Link to={item.customer_type === "buyer" ? `/seller/invoice/${item.id}` : `/company/invoice/${item.id}`} target={'_blank'} className="btn btn-light w-50 mx-1" >
                                                                    {translate(`invoice_${lang}`)} <icon.ExternalLink size={16} />
                                                                </Link>
                                                                <Link to={`/custom/invoice/${item.id}`} target={'_blank'} className="btn btn-dark w-50 mx-1" >
                                                                    {translate(`custom_inv_${lang}`)} <icon.ExternalLink size={16} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby={`edit${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-xl">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/customer/update/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getData();
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">{translate(`group_${lang}`)}<span className="text-danger">*</span></label>
                                                                                <Select options={selectGroup} name="customer_group_id" placeholder={translate(`group_${lang}`)} defaultValue={{ value: item.customer_group_id, label: item.group_name_krd }} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="type" className="form-label">{translate(`account_type_${lang}`)}</label>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="customer_type" id="type1" defaultValue={'buyer'} defaultChecked={item.customer_type === 'buyer'} />
                                                                                    <label className="form-check-label" htmlFor="type1">{translate(`buyer_${lang}`)}</label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="customer_type" id="type2" defaultValue={'seller'} defaultChecked={item.customer_type === 'seller'} />
                                                                                    <label className="form-check-label" htmlFor="type2">{translate(`company_${lang}`)}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="name" className="form-label">{translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="full_name_krd" placeholder={translate(`name_${lang}`)} defaultValue={item.full_name_krd} required />
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="name" className="form-label">{translate(`name_${lang}`)} ({translate("arabic")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="full_name_ar" placeholder={translate(`name_${lang}`)} defaultValue={item.full_name_ar} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="name" className="form-label">{translate(`name_${lang}`)} ({translate("english")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="full_name_en" placeholder={translate(`name_${lang}`)} defaultValue={item.full_name_en} required />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="address_krd" placeholder={translate(`address_${lang}`)} defaultValue={item.address_krd} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("arabic")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="address_ar" placeholder={translate(`address_${lang}`)} defaultValue={item.address_ar} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("english")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="address_en" placeholder={translate(`address_${lang}`)} defaultValue={item.address_en} required />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)}<span className="text-danger">*</span></label>
                                                                        <input type="text" className="form-control" name="phone_number" placeholder={translate(`phone_no_${lang}`)} defaultValue={item.phone_number} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`inv${item.id}`} tabIndex={-1} aria-labelledby={`inv${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`custom_inv_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.post(`/customer/custom-inv`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getData();
                                                                        toast.success(translate(`success_${lang}`))
                                                                        window.$(`#inv${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                                $('input[name="rate"]').val(e.rate);
                                                                                $('#crc').text(e.label)
                                                                            }} />
                                                                            <input type="hidden" name="rate" required />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`amount_${lang}`)}</label>
                                                                            <input type="number" className="form-control" id="amount" name="amount" placeholder="0.00" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="type" className="form-label">{translate(`invoice_type_${lang}`)}</label>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type1" defaultValue={'income'} defaultChecked />
                                                                                    <label className="form-check-label" htmlFor="type1">{translate(`income_${lang}`)}</label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type2" defaultValue={'outcome'} />
                                                                                    <label className="form-check-label" htmlFor="type2">{translate(`outcome_${lang}`)}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="form-label"> {translate(`note_${lang}`)}</label>
                                                                            <textarea className="form-control" name="note" placeholder={translate(`note_${lang}`)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="modal fade" id="company" tabIndex={-1} aria-labelledby="company" aria-hidden="true">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{translate(`group_${lang}`)} {translate(`company_${lang}`)}</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                        </div>
                                        <div className="modal-body">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="orders-tab" data-bs-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="false">{translate(`order_list_${lang}`)}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="order-payment-tab" data-bs-toggle="tab" href="#order_payment" role="tab" aria-controls="order-payment-tab" aria-selected="false">{translate(`order_payment_${lang}`)}</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content border border-top-0 p-3" id="myTabContent">
                                                <div className="tab-pane fade show active" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                                    <table className="table table-sm company-order" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>{translate(`invoice_no_${lang}`)}</th>
                                                                <th>{translate(`store_${lang}`)}</th>
                                                                <th>{translate(`total_cost_${lang}`)}</th>
                                                                <th>{translate(`return_amount_${lang}`)}</th>
                                                                <th>{translate(`discount_${lang}`)}</th>
                                                                <th>{translate(`total_${lang}`)}</th>
                                                                <th>{translate(`paid_${lang}`)}</th>
                                                                <th>{translate(`due_${lang}`)}</th>
                                                                <th>{translate(`rate_${lang}`)}</th>
                                                                <th>{translate(`note_${lang}`)}</th>
                                                                <th>{translate(`attachment_${lang}`)}</th>
                                                                <th>{translate(`date_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orders.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.invoice_no}</td>
                                                                    <td>{item[`store_name_${lang}`]}</td>
                                                                    <td>{commaNumber(item.total)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.return_amount)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.discount)}- {item.symbol}</td>
                                                                    <td>{commaNumber(item.total_disc)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_paid)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_due)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.rate)} {item.symbol}</td>
                                                                    <td>{item.note ? item.note : "-"}</td>
                                                                    <td>{item.attachment ? <a href={`../../../${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                                                    <td>{new Date(item.order_date).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade" id="order_payment" role="tabpanel" aria-labelledby="payment-tab">
                                                    <table className="table table-striped table-sm company-payment" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{translate(`invoice_${lang}`)}</th>
                                                                <th>{translate(`total_${lang}`)}</th>
                                                                <th>{translate(`paid_${lang}`)}</th>
                                                                <th>{translate(`due_${lang}`)}</th>
                                                                <th>{translate(`result_${lang}`)}</th>
                                                                <th>{translate(`date_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {companyPayment.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <a href={`/order/invoice/${item.order_id}`} target={'_blank'} rel="noreferrer">
                                                                            {item.invoice_no}
                                                                        </a>
                                                                    </td>
                                                                    <td>{commaNumber(item.total_disc)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_paid)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_due)} {item.symbol}</td>
                                                                    <td>{item.amount_due < 0 ? translate(`paid_${lang}`) : translate(`due_${lang}`)}</td>
                                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="buyer" tabIndex={-1} aria-labelledby="buyer" aria-hidden="true">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{translate(`group_${lang}`)} {translate(`customers_${lang}`)}</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                        </div>
                                        <div className="modal-body">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="sales-tab" data-bs-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="false">{translate(`sale_list_${lang}`)}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="payment-tab" data-bs-toggle="tab" href="#payment" role="tab" aria-controls="payment-tab" aria-selected="false">{translate(`sale_payment_${lang}`)}</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content border border-top-0 p-3" id="myTabContent">
                                                <div className="tab-pane fade show active" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                                                    <table className="table table-sm buyer-sales" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>{translate(`invoice_no_${lang}`)}</th>
                                                                <th>{translate(`buyer_${lang}`)}</th>
                                                                <th>{translate(`store_${lang}`)}</th>
                                                                <th>{translate(`cost_${lang}`)}</th>
                                                                <th>{translate(`discount_${lang}`)}</th>
                                                                <th>{translate(`total_${lang}`)}</th>
                                                                <th>{translate(`paid_${lang}`)}</th>
                                                                <th>{translate(`due_${lang}`)}</th>
                                                                <th>{translate(`advanced_payment_${lang}`)}</th>
                                                                <th>{translate(`rate_${lang}`)}</th>
                                                                <th>{translate(`note_${lang}`)}</th>
                                                                <th>{translate(`attachment_${lang}`)}</th>
                                                                <th>{translate(`date_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sales.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item[`customer_name_${lang}`]}</td>
                                                                    <td>{item[`store_name_${lang}`]}</td>
                                                                    <td>{commaNumber(item.total)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.discount)}- {item.symbol}</td>
                                                                    <td>{commaNumber(item.total_disc)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_paid)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_due)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.advanced_payment)}%</td>
                                                                    <td>{commaNumber(item.rate)} {item.symbol}</td>
                                                                    <td>{item.note ? item.note : "-"}</td>
                                                                    <td>{item.attachment ? <a href={`/${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                                    <table className="table table-sm buyer-payment" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{translate(`buyer_${lang}`)}</th>
                                                                <th>{translate(`invoice_${lang}`)}</th>
                                                                <th>{translate(`total_${lang}`)}</th>
                                                                <th>{translate(`paid_${lang}`)}</th>
                                                                <th>{translate(`due_${lang}`)}</th>
                                                                <th>{translate(`result_${lang}`)}</th>
                                                                <th>{translate(`date_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {buyerPayment.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item[`customer_name_${lang}`]}</td>
                                                                    <td>
                                                                        <a href={`/sale/invoice/${item.sale_id}`} target={'_blank'} rel="noreferrer">
                                                                            {item.sale_id}
                                                                        </a>
                                                                    </td>
                                                                    <td>{commaNumber(item.total_price)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_paid)} {item.symbol}</td>
                                                                    <td>{commaNumber(item.amount_due)} {item.symbol}</td>
                                                                    <td>{item.amount_due < 0 ? translate(`paid_${lang}`) : translate(`due_${lang}`)}</td>
                                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Customers;