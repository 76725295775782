/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from 'comma-number';

const New = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const productionId = JSON.parse(localStorage.getItem('productionId'));

    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        if (productionId) {
            $('#productions').hide();
            $('#production_item').show();
        } else {
            $('#productions').show();
            $('#production_item').hide();
        }
    }, [productionId]);


    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`store_name_${lang}`],
            };
        }));
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/orderItem/item-summary');
        setItems(data.map((item) => {
            return {
                value: item.item_id,
                label: `${item.item_code} - ${item[`item_name_${lang}`]} - ${commaNumber(item.max_price.toFixed(2))} $`,
                item_unit: item.item_unit,
                item_price: item.max_price,
                item_balance: item.item_balance,
            };
        }));
    }

    const itemSelected = (e) => {
        axios.post(`/productionItem/add`, {
            production_id: productionId,
            item_id: e.value,
            item_unit: e.item_unit,
            price: e.item_price,
            currency_id: 1
        }).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                getProductionItem();
            }
        })
    }

    const [productionItem, setProductionItem] = useState([]);
    const [total, setTotal] = useState([]);
    const getProductionItem = async () => {
        const { data } = await axios.get(`/productionItem/list/${JSON.parse(localStorage.getItem('productionId'))}`);
        setProductionItem(data);
        const groupedData = data.reduce((acc, item) => {
            if (!acc[item.symbol]) {
                acc[item.symbol] = {
                    symbol: item.symbol,
                    total_price: 0
                };
            }
            acc[item.symbol].total_price += item.total_price;
            return acc;
        }, {});
        setTotal(Object.values(groupedData));

    }


    const [selectProduct, setSelectProduct] = useState([]);
    const getProductGroup = async () => {
        const { data } = await axios.get("/production/product");
        setSelectProduct(data.map((item) => {
            return {
                value: item.id,
                label: item[`product_name_${lang}`]
            };
        }));
    };

    const [contract, setCountract] = useState([])
    const getContract = async () => {
        const { data } = await axios.get('/contract/list');
        setCountract(data.map((item) => {
            return {
                value: item.id,
                label: item[`title_${lang}`],
            };
        }));
    }

    useEffect(() => {
        getItems();
        getStore();
        getContract();
        getProductionItem();
        getProductGroup();
    }, []);

    const getCollection = async () => {
        const { data } = await axios.get(`/collection/thisproduct/${productionId}`);
        data.map(async (e) => {
            const selectedItem = items.find((item) => item.value === e.item_id);
            if (selectedItem) {
                const item_balance =
                    selectedItem.item_balance -
                    (selectedItem.item_unit === 'qty'
                        ? e.quantity
                        : selectedItem.item_unit === 'm'
                            ? e.height * e.width
                            : e.weight);
                if (item_balance < 0) {
                    toast.error(translate(`item_not_available_${lang}`));
                    return;
                }
                await axios.post(`/productionItem/collection`, {
                    production_id: productionId,
                    item_id: e.item_id,
                    quantity: e.quantity,
                    height: e.height,
                    width: e.width,
                    weight: e.weight,
                    price: selectedItem.item_price,
                    currency_id: 1
                }).then(res => {
                    if (isNaN(res.data)) {
                        toast.error(res.data, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        getItems();
                        getProductionItem();
                    }
                });
            }

        });
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`productions_${lang}`)} </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card" id="productions" style={{ display: "none" }}>
                    <div className="card">
                        <form className="form-sample" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData);
                            axios.post(`/production/add`, data).then(res => {
                                if (isNaN(res.data)) {
                                    toast.error(res.data, {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                } else {
                                    localStorage.setItem('productionId', JSON.stringify(res.data));
                                    $('#productions').hide();
                                    $('#production_item').show();
                                    getProductionItem();
                                }
                            });
                        }}>
                            <div className="card-body">
                                <h5 className="card-title">{translate(`productions_${lang}`)} </h5>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="row">
                                            <div className="col-md-7 mb-3">
                                                <label className="form-label"> {translate(`product_${lang}`)}</label>
                                                <Select options={selectProduct} name="product_name_id" placeholder={translate(`product_${lang}`)} required />
                                            </div>
                                            <div className="col-md-5 mb-3">
                                                <label className="form-label"> {translate(`contract_${lang}`)}</label>
                                                <Select options={contract} name="contract_id" placeholder={translate(`contract_${lang}`)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">{translate(`height_${lang}`)}</label>
                                                <input type="text" className="form-control" name="product_height" defaultValue={0} placeholder="0" required />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">{translate(`width_${lang}`)}</label>
                                                <input type="text" className="form-control" name="product_width" defaultValue={0} placeholder="0" required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">{translate(`note_${lang}`)}</label>
                                                <textarea className="form-control" name="note" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label"> {translate(`store_${lang}`)}</label>
                                                <Select options={store} name="store_id" placeholder={translate(`store_${lang}`)} required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label"> {translate(`attachment_${lang}`)}</label>
                                                <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                <input type="hidden" name="attachment" defaultValue={selectedFile} />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">{translate(`next_${lang}`)}
                                    <icon.ArrowLeft className="mx-2 mt-1" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card" id="production_item" style={{ display: "none" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{translate(`select_item_${lang}`)} </h5>
                            <div className="row">
                                <div className="col-md-4 mb-3 mt-2">
                                    <div className="input-group mb-3">
                                        <div className="col-12">
                                            <Select options={items} placeholder={translate(`select_item_${lang}`)} onChange={(e) => { itemSelected(e); }} />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3 mt-2">
                                    <div className="input-group mb-3">
                                        <button type="button" className="btn btn-primary" onClick={() => getCollection()}>{translate(`autoadd_${lang}`)} <icon.Anchor /> </button>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`group_${lang}`)}</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`height_${lang}`)}</th>
                                                    <th>{translate(`width_${lang}`)}</th>
                                                    <th>{translate(`weight_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productionItem.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item[`group_name_${lang}`]}</td>
                                                        <td>{item[`item_name_${lang}`]}</td>
                                                        <td>{item.quantity ?
                                                            <input type="number" className="form-control" name="quantity" step="any" defaultValue={item.quantity} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - e.target.value;
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/productionItem/update/${item.id}`, { quantity: e.target.value }).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }} /> : '-'}
                                                        </td>
                                                        <td>{item.height ?
                                                            <input type="number" className="form-control" name="height" step="any" defaultValue={item.height} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - (e.target.value * item.width);
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/productionItem/update/${item.id}`, { height: e.target.value }).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.width ?
                                                            <input type="number" className="form-control" name="width" step="any" defaultValue={item.width} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - (e.target.value * item.height);
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/productionItem/update/${item.id}`, { width: e.target.value }).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>{item.weight ?
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.weight} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                const x = items.find(x => x.value === item.item_id);
                                                                const item_balance = x.item_balance - e.target.value;
                                                                if (item_balance < 0) {
                                                                    toast.error(translate(`item_not_available_${lang}`));
                                                                    return;
                                                                }
                                                                axios.put(`/productionItem/update/${item.id}`, { weight: e.target.value }).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }} />
                                                            : '-'}
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="weight" step="any" defaultValue={item.price} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/productionItem/update/${item.id}`, { price: e.target.value }).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>{commaNumber(item.total_price)} {item.symbol}</td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/productionItem/list/${item.id}`).then(() => {
                                                                    getProductionItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mt-3">
                                <div className="col-md-5 mb-3">
                                </div>
                                <div className="col-md-2 mb-3"></div>
                                <div className="col-md-5 mb-3">
                                    <h5 className="card-title">{translate(`production_summery_${lang}`)}</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <tbody>
                                                <tr >
                                                    <td className="text-bold-800">{translate(`area_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(productionItem.reduce((total, item) => total + (item.height * item.width), 0).toFixed(2))} {translate(`sqm_${lang}`)}</td>
                                                </tr>
                                                <tr className="bg-light">
                                                    <td className="text-bold-800">{translate(`qty_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(productionItem.reduce((total, item) => total + item.quantity, 0).toFixed(2))} {translate(`qty_text_${lang}`)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-800"> {translate(`weight_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(productionItem.reduce((total, item) => total + (item.weight), 0).toFixed(2))} {translate(`kg_${lang}`)}</td>
                                                </tr>
                                                {
                                                    total.map((item, index) => (
                                                        <tr className="bg-dark text-white" key={index}>
                                                            <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                            <td className="text-bold-800 text-end">{commaNumber(item.total_price.toFixed(2))} {item.symbol}</td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => {
                                            localStorage.removeItem('currency');
                                            localStorage.removeItem('productionId');
                                            navigate('/productions/list');
                                        }} >
                                            {translate(`save_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default New;