/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Select from "react-select";

const Expenses = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [expenses, setExpeses] = useState([]);
    const getExpenses = async () => {
        const { data } = await axios.get("/expenses/list");
        setExpeses(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            order: [[0, 'desc']],
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0, 1, 4, 8, 9]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        console.log("Could not parse: ", i);
                                        return 0;
                                    }
                                    return parsed;
                                };
                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                    },
                                    "د.ع": {
                                        amount: 0,
                                    }
                                };

                                var columns = [5];
                                var fields = ['amount'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });

                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });
                            },
                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                            },
                            "د.ع": {
                                amount: 0,
                            }
                        };

                        var columns = [5];
                        var fields = ['amount'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: item.symbol
            };
        }));
    }

    const [safe, setsafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafe(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name_${lang}`]}`
            };
        }));
    };
    const [contract, setcontract] = useState([]);
    const getContract = async () => {
        const { data } = await axios.get("/contract/list");
        setcontract(data.map((item) => {
            return {
                value: item.id,
                label: `${item.id} - ${item[`title_${lang}`]}`
            };
        }));
    };
    const [typeList, setTypeList] = useState([]);
    const [expensesType, setExpensesType] = useState([]);
    const getExpensesType = async () => {
        const { data } = await axios.get("/expenses/type");
        setExpensesType(data.map((item) => {
            return {
                value: item.id,
                label: `${item.e_code} - ${item.name}`
            };
        }));
        setTypeList(data);
    };

    const [expensesContent, setExpensesContent] = useState([]);
    const getExpensesContent = async (id) => {
        const { data } = await axios.get(`/expenses/content/${id}`);
        setExpensesContent(data);
    };

    const upload = () => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $("#attachment").val(res.data);
                        $(`#upattachment`).append(`<img src="../../../${res.data}" className="img-thumbnail viewImg" alt=${res.data} />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                        axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                            if (res.data !== "err") {
                                $("#attachment").val('');
                            } else {
                                toast.error(translate(`error_${lang}`));
                            }
                        })
                    })
                })
            }
        })
    }

    const upload2 = (id) => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $(`#attachment${id}`).val(res.data);
                        $(`#upattachment${id}`).append(`<img src="../../../${res.data}" className="img-thumbnail viewImg${id}" alt=${res.data} />`)
                    }
                    $(`.viewImg${id}`).click(function () {
                        $(this).remove()
                        axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                            if (res.data !== "err") {
                                $(`#attachment${id}`).val('');
                            } else {
                                toast.error(translate(`error_${lang}`));
                            }
                        })
                    })
                })
            }
        })
    }

    useEffect(() => {
        getSafe();
        getCurrency();
        getExpenses();
        getContract();
        getExpensesType();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`expenses_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`expenses_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-light mx-2" data-bs-toggle="modal" data-bs-target="#typexpenses">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`expenses_type_${lang}`)}</span>
                                        </button>
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#newexpenses">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`new_expenses_${lang}`)}</span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="typexpenses" tabIndex="-1" aria-labelledby="typexpenses-label" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="confirmation-modal-label">{translate(`expenses_type_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="from">
                                                    <div className="mb-3">
                                                        <label htmlFor="name" className="col-12 form-label">{translate(`expenses_type_${lang}`)}</label>
                                                        <div className="form-group row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" name="name" placeholder={translate(`expenses_type_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="e_code" className="col-12 form-label">{translate(`code_${lang}`)}</label>
                                                        <div className="form-group row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" name="e_code" placeholder={translate(`code_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-primary" onClick={function () {
                                                            axios.post('/expenses/type', {
                                                                name: document.querySelector('input[name="name"]').value,
                                                                e_code: document.querySelector('input[name="e_code"]').value
                                                            }).then((res) => {
                                                                if (res.data !== "err") {
                                                                    getExpensesType();
                                                                } else {
                                                                    toast.error(res.data);
                                                                }
                                                            })
                                                        }}>{translate(`add_${lang}`)}</button>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{translate(`expenses_type_${lang}`)}</th>
                                                                <th>{translate(`code_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {typeList.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.e_code}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="newexpenses" tabIndex={-1} aria-labelledby="newexpenses" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{translate(`new_expenses_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post("/expenses/header", data).then(res => {
                                                    if (!isNaN(res.data)) {
                                                        $('#expenses_header_id').val(res.data[0]);
                                                        getExpensesContent(res.data[0]);
                                                        getExpenses();
                                                    }
                                                })
                                            }}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="invoice_no" className="col-12 form-label">{translate(`invoice_no_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" id="invoice_no" name="invoice_no" placeholder={translate(`invoice_no_${lang}`)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="account_id" className="form-label">{translate(`safe_name_${lang}`)}</label>
                                                            <Select options={safe} name="safe_id" placeholder={translate(`safe_name_${lang}`)} required />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="contract_id" className="form-label">{translate(`contract_${lang}`)}</label>
                                                            <Select options={contract} name="contract_id" placeholder={translate(`contract_${lang}`)} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="currency_id" className="form-label">{translate(`currency_${lang}`)}</label>
                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="description" className="form-label">{translate(`note_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <textarea className="form-control" id="description" name="description" placeholder={translate(`note_${lang}`)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label> {translate(`attachment_${lang}`)}</label>
                                                        <input type="hidden" name="attachment" id="attachment" />
                                                        <div className="form-control text-center" onClick={() => upload()}>
                                                            <icon.Upload />
                                                        </div>
                                                        <br />
                                                        <div align="center" id="upattachment"></div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">{translate(`doc_date_${lang}`)}</label>
                                                        <input type="date" className="form-control" name="doc_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">{translate(`sleep_date_${lang}`)}</label>
                                                        <input type="date" className="form-control" name="sleep_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#confirmation-modal" data-bs-dismiss="modal">
                                                        <span className="ms-2">{translate(`next_${lang}`)}</span>
                                                        <icon.ArrowLeft />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="confirmation-modal" tabIndex="-1" aria-labelledby="confirmation-modal-label" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="confirmation-modal-label">{translate(`detail_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    data.expenses_header_id = $('#expenses_header_id').val();
                                                    axios.post("/expenses/content", data).then(res => {
                                                        if (res.data === "done") {
                                                            getExpensesContent($('#expenses_header_id').val());
                                                            getExpenses();
                                                        }
                                                    })
                                                }}>
                                                    <input type="hidden" name="expenses_header_id" id="expenses_header_id" />
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="type_expenses_id" className="form-label">{translate(`expenses_type_${lang}`)}</label>
                                                            <Select options={expensesType} name="type_expenses_id" placeholder={translate(`expenses_type_${lang}`)} required />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="title" className="col-12 form-label">{translate(`note_${lang}`)}</label>
                                                        <div className="form-group row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" id="title" name="title" placeholder={translate(`note_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="amount" className="col-12 form-label">{translate(`amount_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <input type="number" className="form-control" id="amount" step={"any"} name="amount" placeholder="0.00" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="sumbit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    <table className="table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>{translate(`invoice_no_${lang}`)}</th>
                                                                <th>{translate(`expenses_type_${lang}`)}</th>
                                                                <th>{translate(`code_${lang}`)}</th>
                                                                <th>{translate(`safe_name_${lang}`)}</th>
                                                                <th>{translate(`title_${lang}`)}</th>
                                                                <th>{translate(`amount_${lang}`)}</th>
                                                                <th>{translate(`options_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expensesContent.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.invoice_no}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.e_code}</td>
                                                                    <td>{item.safe_name_krd}</td>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.amount} {item.symbol}</td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-danger" onClick={function () {
                                                                            axios.delete(`/expenses/content/${item.id}`).then(() => {
                                                                                getExpenses();
                                                                                getExpensesContent(item.expenses_header_id);
                                                                            })
                                                                        }}><icon.Trash size="16" /></button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`invoice_no_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`code_${lang}`)}</th>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>{translate(`total_cost_${lang}`)}</th>
                                        <th>{translate(`attachment_${lang}`)}</th>
                                        <th>{translate(`note${lang}`)}</th>
                                        <th>{translate(`contract_ar`)}</th>
                                        <th>{translate(`doc_date_${lang}`)}</th>
                                        <th>{translate(`sleep_date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.invoice_no}</td>
                                            <td>{item.store_name_krd}</td>
                                            <td>{item.safe_code}</td>
                                            <td>{item.safe_name_krd}</td>
                                            <td>{commaNumber(item.amount)} {item.symbol}</td>
                                            <td>{item.attachment ? <a href={`/ ${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                            <td>{item.note ? item.note : "-"}</td>
                                            <td>{item.title_ar}</td>
                                            <td>{new Date(item.doc_date).toLocaleDateString()}</td>
                                            <td>{new Date(item.sleep_date).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser") && (
                                                    <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#editexpenses${item.id}`}>
                                                        <icon.Edit size="16" />
                                                    </span>
                                                )}
                                            </td >
                                            <div className="modal fade" id={`editexpenses${item.id}`} tabIndex={-1} aria-labelledby={`editexpenses${item.id}`} aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> {translate(`edit_${lang}`)} </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/expenses/header/${item.id}`, data).then(res => {
                                                                if (res.data === "done") {
                                                                    getExpensesContent(item.id);
                                                                    getExpenses();
                                                                }
                                                            })
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <div className="form-group row">
                                                                        <label htmlFor="invoice_no" className="col-12 form-label">{translate(`invoice_no_${lang}`)}</label>
                                                                        <div className="col-12">
                                                                            <input type="text" className="form-control" id="invoice_no" name="invoice_no" defaultValue={item.invoice_no} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="name_ku" className="form-label">{translate(`safe_name_${lang}`)}</label>
                                                                        <Select options={safe} name="safe_id" defaultValue={{ value: item.safe_id, label: item.safe_name_krd }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="contract_id" className="form-label">{translate(`contract_${lang}`)}</label>
                                                                        <Select options={contract} name="contract_id" defaultValue={{ value: item.contract_id, label: item.title_ar }} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="name_ku" className="form-label">{translate(`currency_${lang}`)}</label>
                                                                        <Select options={currency} name="currency_id" defaultValue={{ value: item.currency_id, label: item.symbol }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-group row">
                                                                        <label htmlFor="description" className="form-label">{translate(`note${lang}`)}</label>
                                                                        <div className="col-12">
                                                                            <textarea className="form-control" name="description" defaultValue={item.description} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label> {translate(`attachment_${lang}`)}</label>
                                                                    <input type="hidden" name="attachment" id={`attachment${item.id}`} />
                                                                    <div className="form-control text-center" onClick={() => upload2(item.id)}>
                                                                        <icon.Upload />
                                                                    </div>
                                                                    <br />
                                                                    <div align="center" id={`upattachment${item.id}`}>
                                                                        {item.attachment ? <a href={`/ ${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">{translate(`doc_date_${lang}`)}</label>
                                                                    <input type="date" className="form-control" name="doc_date" defaultValue={new Date(new Date(item.doc_date).getTime() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]} />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">{translate(`sleep_date_${lang}`)}</label>
                                                                    <input type="date" className="form-control" name="sleep_date" defaultValue={new Date(new Date(item.sleep_date).getTime() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]} />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                <button type="submit" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target={`#confirmationModal${item.id}`} data-bs-dismiss="modal">
                                                                    <span className="ms-2">{translate(`next_${lang}`)}</span>
                                                                    <icon.ArrowLeft />
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal fade" id={`confirmationModal${item.id}`} tabIndex="-1" aria-labelledby="confirmation-modal-label" aria-hidden="true">
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id={`confirmationMdal${item.id}`}> {translate(`detail_${lang}`)}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.expenses_header_id = item.id
                                                                axios.post("/expenses/content", data).then(res => {
                                                                    if (res.data === "done") {
                                                                        getExpensesContent(item.id);
                                                                        getExpenses();
                                                                    }
                                                                })
                                                            }}>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="type_expenses_id" className="form-label">{translate(`expenses_type_${lang}`)}</label>
                                                                        <Select options={expensesType} name="type_expenses_id" placeholder={translate(`expenses_type_${lang}`)} required />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="title" className="col-12 form-label">{translate(`note_${lang}`)}</label>
                                                                    <div className="form-group row">
                                                                        <div className="col-12">
                                                                            <input type="text" className="form-control" id={`title${item.id}`} name="title" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-group row">
                                                                        <label htmlFor="amount" className="col-12 form-label">{translate(`amount_${lang}`)}</label>
                                                                        <div className="col-12">
                                                                            <input type="number" className="form-control" id={`amount${item.id}`} step={"any"} name="amount" placeholder="0.00" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <button type="submit" className="btn btn-primary" >{translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                            <div className="table-responsive">
                                                                <table className="table table-sm table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{translate(`invoice_no_${lang}`)}</th>
                                                                            <th>{translate(`expenses_type_${lang}`)}</th>
                                                                            <th>{translate(`code_${lang}`)}</th>
                                                                            <th>{translate(`safe_name_${lang}`)}</th>
                                                                            <th>{translate(`title_${lang}`)}</th>
                                                                            <th>{translate(`amount_${lang}`)}</th>
                                                                            <th>{translate(`options_${lang}`)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {expensesContent && expensesContent.map((obj) => (
                                                                            <tr key={obj.id}>
                                                                                <td>{obj.invoice_no}</td>
                                                                                <td>{obj.name}</td>
                                                                                <td>{obj.e_code}</td>
                                                                                <td>{obj.safe_name_krd}</td>
                                                                                <td>{obj.title}</td>
                                                                                <td>{obj.amount} {obj.currency}</td>
                                                                                <td>
                                                                                    <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                                                        axios.delete(`/expenses/content/${obj.id}`).then(() => {
                                                                                            getExpenses();
                                                                                            getExpensesContent(item.id);
                                                                                        })
                                                                                    }}>
                                                                                        <icon.Trash size="16" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr >
                                    ))}
                                </tbody >
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table >
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Expenses;